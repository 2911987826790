<template>
    <div class="body">
        <div class="container">
            <div class="content" v-if="task">
                <div class="monitor">
                    <!-- <div class="process-total">
                        任务总进度
                        <div class="process-num">{{task.task_process.speed_num}}%</div>
                    </div>
                    <div class="task-ford">
                        <div class="ford-item">
                            <div class="">指派人</div>
                            <strong>管理员</strong>
                        </div>
                        <div class="ford-item">
                            <div class="">学习进度</div>
                            <strong>{{task.task_process.speed_num}}%</strong>
                        </div>
                        <div class="ford-item">
                            <div class="">考试及格</div>
                            <strong>{{task.task_process.status == 1?1:0}}/1场</strong>
                        </div>
                    </div>
                    <div class="task-ford-desc">
                        合格标准：学习进度完成100% 考试及格
                    </div> -->
                    <img :src="task.cover">
                </div>
                <div class="task-sumury">
                    <div class="title">{{task.name}}</div>
                    <!-- <span class="p1 light-color">
                        <strong>{{task.credit}}学分</strong>
                        {{task.task_process.start_time}}~{{task.task_process.end_time}} 
                    </span>-->
                    <div class="lesson-desc">
                        
                    
                        <div class="toolbar-box">
                            <van-icon name="good-job" :color="color1" size="20" @click="dz" style="margin-right: 15px;" />
                            <van-icon name="star" :color="color2" size="20" @click="sc" />
                            
                        </div>
                        
                        <span>已有{{visit}}人观看</span>
                    </div>
                    <!-- <div class="p2">培训目的：{{task.name}}</div> -->
                </div>
                <div class="separator-line full-width"></div>
                <div class="section-lession">
                    <van-tabs v-model="activeName" title-class="tab-ranking">
                        <van-tab title="简介" name="a">
                            <div class="lession-chap-list">{{task.name}}</div>
                        </van-tab>
                        <van-tab title="目录" name="b">
                            <div class="lession-chap-list">
                                <div class="chap" v-for="(lesson, index) in task.lessons" 
                                  :key="lesson.id" @click="toLesson(index)">
                                    <div class="chap-info">
                                        <div class="top">
                                            <img :src="getLessonIcon(lesson.lesson_type)" >
                                            {{getLessonTypeName(lesson.lesson_type)}}
                                        </div>
                                        <div class="text-ellipsis">
                                            {{lesson.name}}
                                        </div>
                                    </div>
                                    <div class="chap-process">
                                        <van-progress :percentage="getPercent(lesson)" pivot-text="" />                    
                                        <div>{{getPercent(lesson)}}%</div>
                                    </div>
                                </div>
                            </div>
                        </van-tab>
                        <van-tab title="评论" name="c">
                            <div class="lession-chap-list">
                                <div class="btn-chat">
                                    <!-- <van-icon name="comment-circle" /> -->
                                    <!-- <van-icon name="comment-circle" size="30" color="#1989fa" /> -->
                                <van-button @click="reply" type="primary" color="#1989fa" size="mini">我也说说</van-button>
                                </div>
                                <div class="no-data" v-if="replyList.length == 0">
                                无数据
                                </div>
                                <div class="reply-item" :key="index" v-for="(reply, index) in replyList">
                                    <div class="item-head">
                                        <div class="user">
                                            <van-image
                                              round
                                              width="36"
                                              height="36"
                                              style="padding-right: 6px;"
                                              :src="reply.avatar"
                                            />
                                            <span>{{reply.username}}</span>
                                        </div>
                                        <div class="time">
                                            {{reply.add_time}}
                                        </div>
                                    </div>
                                    <div class="item-content">
                                        {{reply.title}}
                                    </div>
                                </div>
                            </div>
                        </van-tab>
                    </van-tabs>
                    <!-- <div class="lession-header">课程</div> -->
                    
                </div>
               <div class="separator-line full-width" v-if="task.examid"></div>
               <div class="section-exam" v-if="task.examid">
                   <div class="section-header">
                       {{task.exam.title}}
                       <span class="light-color">
                           （共{{task.exam.qs_num}}道题,计时{{task.exam.minute}}分钟）</span>
                   </div>
                   <!-- <van-button disabled type="info">去考试</van-button> -->
                   <div class="qu">
                       <span class="qu-btn" @click="toExam" v-if="task.coursewareUser && task.coursewareUser.status == 0">去考试 
                       </span>
                       <span class="qu-btn" @click="toResul" v-if="task.coursewareUser && task.coursewareUser.status != 0">查看结果
                       </span>
                   </div>
                </div>
                 <div class="footer full-width">
                     <van-button type="info" @click="toLesson(0)">观看课程</van-button>
                     <!-- <van-button type="info" @click="toResul" v-if="task.task_process.status != 0">查看结果</van-button> -->
                 </div>
             </div> 
        </div>
    </div>
</template>

<script>
    import {toast, checkLogin } from '@/utils/common.js';
    import { getCoursewareDetail, getCoursewarePraise
    , getCoursewareCollection, updateCoursewarePraise
    , updateCoursewareCollection, getCoursewareVisit,
    getCoursewareReply, saveCoursewareReply} from '@/api/task.js';
    import { mapState } from 'vuex';
    var id, tsid;
    export default{
        data(){
           return{
               task: null,
               src: require('@/assets/images/video-s.png'),
               tsid: '',
               tid: '',
               activeName: 'b',
               color1: '#999999',
               color2: '#999999',
               visit: 0,
               replyList:[], // 课件的评论列表
           } 
        },
        activated() {
            id = this.tid = this.$route.query.id; // 任务id2
            tsid = this.tsid = this.$route.query.tsid;// 任务结果id
            
            checkLogin().then(()=> {
                this.getPageData();
            })
            this.hideTab();
          
        },
        computed: mapState(['token']),
        methods:{
            getPageData(){
                Promise.all([
                    this.getDetail(id, tsid), this.getPraise(), this.getCollection(), 
                    this.getVisit(),this.getReply()
                ]);
            },
            getDetail(id, tsid){
                getCoursewareDetail(id, tsid).then((ret)=>{
                    this.task = ret.data;
                });
            },
            getPraise(){
                getCoursewarePraise(id).then((ret)=>{
                    if(ret.data){
                        this.color1 = '#ff9800';
                    }
                    
                });
            },
            getCollection(){
                getCoursewareCollection(id).then((ret)=>{
                    if(ret.data){
                        this.color2 = '#ff9800';
                    }
                });
            },
            getReply(){
                getCoursewareReply(id).then((ret)=>{
                    console.log(ret.data);
                    this.replyList = ret.data;
                });
            },
            toLesson(index){
                var lesson = this.task.lessons[index];
                if (lesson.lesson_type == 0) {
                    this.$router.push(`/view?id=${id}&cur=${index}`);
                } else if(lesson.lesson_type == 1 || lesson.lesson_type == 2){
                    this.$router.push(`/coffice?id=${id}&cur=${index}`);
                }else if(lesson.lesson_type == 3){
                    this.$router.push(`/cpdf?id=${id}&cur=${index}`);
                }
                
            },
            toResul(){
                if(!this.task){
                    return;
                }
                this.$router.push(`/result?tid=${this.tid}&examid=${this.task.examid}&type=c`);
            },
            toExam(){
                if(!this.task){
                    return;
                }
                if(this.task.coursewareUser.speed_num !=100){
                    toast('抱歉,你的课程尚未看完，不能前去考试', 'error');
                    return;
                }
                this.$router.push(`/exam?tid=${this.tid}&examid=${this.task.examid}&type=c`);
            },
            getPercent(lesson){
                // 区分下类型 只有视频才存储进度
                var clu = lesson.clu;
                if(clu){
                    if(lesson.lesson_type == 0){
                        var div = Math.floor(Number(clu.speed_num)*10000/Number(clu.total_num));
                        return div/100;
                    }
                    return 100;
                }
                return 0;
                
            },
            hideTab(){
                //console.log(document.querySelector('#vantab'));
                document.querySelector('#vantab').classList.add('tab-hide');
            },
            dz(){
                var data;
                if (this.color1 == '#999999') {
                    data = {
                        flag: 1,
                        name: this.task.name,
                    };
                    updateCoursewarePraise(id, data).then(()=> {
                        this.color1 = '#ff9800';
                    })
                    
                } else{
                    data = {
                        flag: 0,
                        name: this.task.name,
                    };
                    updateCoursewarePraise(id, data).then(()=> {
                        this.color1 = '#999999';
                    })
                }
            },
            sc(){
                var data;
                if (this.color2 == '#999999') {
                    data = {
                        flag: 1,
                        name: this.task.name,
                    };
                    updateCoursewareCollection(id, data).then(()=> {
                        this.color2 = '#ff9800';
                    })
                    
                } else{
                    data = {
                        flag: 0,
                        name: this.task.name,
                    };
                    updateCoursewareCollection(id, data).then(()=> {
                        this.color2 = '#999999';
                    })
                }
            },
            getVisit(){
                getCoursewareVisit(id).then((ret)=> {
                    this.visit = ret.data.count;
                })
            },
            reply(){
                var that = this;
                that.$dd.ui.input.plain({
                    placeholder: '说点什么吧', //占位符
                    text: '', //默认填充文本
                    onSuccess: function(data) {
                        var text = data.text;
                        if(!text){
                            return;
                        }
                        var data2 ={
                            title: text,
                            coursewareid: id,
                            cname: that.task.name,
                        };
                        saveCoursewareReply(data2).then(()=> {
                            that.getReply();
                        })
                    },
                })
            },
            getLessonIcon(lessonType){
                switch (lessonType){
                    case 0:
                        return require('@/assets/images/video-s.png');
                    case 1:
                        return require('@/assets/images/ppt-s.png');
                    case 2:
                        return require('@/assets/images/word-s.png');
                    case 3:
                        return require('@/assets/images/pdf-s.png');
                    default:
                        return '';
                }
            },
            getLessonTypeName(lessonType){
                switch (lessonType){
                    case 0:
                        return '视频';
                    case 1:
                        return 'ppt';
                    case 2:
                        return 'word';
                    case 3:
                        return 'pdf';
                    default:
                        return '其他';
                }
            },
        },
    }
</script>

<style scoped>
    .container{
       font-size: 14px; 
       bottom: 50px;
       max-height: 100%;
    }
    .content{
        height: 100%;
        overflow: auto;
    }
    .monitor{
        /* height: 26.5rem; */
        margin-top: 20px;
        /* background-color: #465ecc; */
        color: #fff;
        border-radius: 5px;
        overflow: auto;
        text-align: center;
    }
    .toolbar-box{
        display: flex;
        /* justify-content: space-between; */
    }
    .toolbar-box img{
        width: 2rem;
        height: 2rem;
        padding-right: 10px;
    }
    .process-total{
        margin-top: 3.4rem;
    }
    .process-total .process-num{
        font-size: 30px;
        font-weight: bold;
    }
    .task-ford{
        display: flex;
        width: 31rem;
        margin: 2.5rem auto 1.5rem;
        padding: 10px 0;
        background-color: #566ed0;
        border-radius: 5px;
        line-height: 1.8;
    }
    .task-ford .ford-item{
        width: 33.33%;
    }
    .task-sumury{
        line-height: 1.5;
        font-size: 16px;
        margin: 15px 0;
    }
    .task-sumury .title{
        font-size: 20px;
        font-weight: bold;
    }
    .task-sumury strong{
        color: #f29901;
    }
    .separator-line{
        height: 8px;
        background-color: #f2f2f2;
        margin: 17px 0 23px auto;
    }
    .lession-chap-list{
        position: relative;
        margin-top: 10px;
    }
    .section-lession{
        font-size: 16px;
    }
    .section-lession .lession-header{
        border-bottom: 1px solid #f2f2f2;
        margin-bottom: 1.2rem;
        padding-bottom: 1.8rem;
    }
    .section-lession .chap{
        display: flex;
        background-color: #f7f8fa;
        border-radius: 3px;
        padding: 1.5rem;
        margin-bottom: 1rem;
    }
    .section-lession .chap-info{
        width: 27rem;
    }
    .section-lession .chap-process{
        width: 6rem;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        color: #566ed0;
        flex-direction: column;
        font-size: 14px;
    }
    .lesson-desc{
        display: flex;
        justify-content: space-between;
        color: #666;
        font-size: 15px;
    }
    .section-lession .chap .top{
        display: flex;
        align-items: center;
    }
    .section-lession .chap img{
        width: 1.6rem;
        height: 1.7rem;
        padding-right: 5px;
    }
    .section-lession .chap .van-progress{
        display: block;
        width: 100%;
        margin-bottom: 2px;
    }
    .section-exam{
        font-size: 16px;
        padding-bottom: 20px;
    }
    .section-exam .section-header{
        /* padding-bottom: 1.8rem; */
    }
    .section-exam .light-color{
        font-size: 14px;
    }
    .footer{
        position: fixed;
        width: 100vw;
        height: 4.5rem;
        bottom: 0;
        border-top: 1px solid #e8e8e8;
    }
    .footer button{
        margin-top: 0.5rem;
        height: 3.5rem;
        width: 34.5rem;
        line-height: 3.5rem;
        font-size: 1.4rem;
        background-color: #465ecc;
        margin-left: 1.5rem;
    }
    .reply-item {
        font-size: 14px;
        color: #333;
        padding: 10px 0;
    }
    .reply-item .item-head{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .reply-item .user{
        display: flex;
        /* justify-content: space-between; */
        align-items: center;
    }
    .reply-item .time{
        color: #999;
    }
    .btn-chat{
        /* position: absolute;
        right: 0;
        top: -10px; */
        text-align: right;
    }
    .qu{
        /* text-decoration: underline; */
        text-align: center;
        padding-right: 25px;
        font-size: 16px;
    }
    .qu .qu-btn{
        border-bottom: 1px solid #465ecc;
        color: #465ecc;
    }
    .qu img{
        width: 30px;
        height: 30px;
        vertical-align: middle;
        
    }
</style>